import { createTypedMap } from "../types/reducers";
import { RESET_OUTPUT, SET_INVALID_OUTPUT, UPDATE_OUTPUT } from "../constants/action-types";
export var INITIAL_STATE = createTypedMap(null);
var reducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case UPDATE_OUTPUT:
            return state["delete"]('error').merge(action.values);
        case SET_INVALID_OUTPUT:
            return state.update('error', function () { return true; });
        case RESET_OUTPUT:
            return INITIAL_STATE;
    }
    return state;
};
export default reducer;
