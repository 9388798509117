import { createTypedMap } from "../types/reducers";
import { INVALIDATE_PDF, SET_PDF } from "../constants/action-types";
var INITIAL_STATE = createTypedMap({
    uri: null,
    name: "",
});
export var getPdfName = function (_a) {
    var _b = _a.general, projectNumber = _b.projectNumber, projectName = _b.projectName;
    return projectNumber + "-" + projectName + ".pdf";
};
var reducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case SET_PDF:
            return state.merge(action.payload);
        case INVALIDATE_PDF:
            return INITIAL_STATE;
    }
    return state;
};
export default reducer;
