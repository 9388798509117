var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { lazy, Suspense } from "react";
import WebFont from "webfontloader";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import blueGrey from "@material-ui/core/colors/blueGrey";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import DownloadButton from "./DownloadButton";
import Loading from "../components/Loading";
import logo from "../images/logo.svg";
import { APP_TITLE } from "../constants";
import { HARDCOVER_TITLE, PAPERBACK_TITLE, SWISS_BIND_TITLE } from "../constants/titles";
import { HARDCOVER_URL, PAPERBACK_URL, SWISS_BIND_URL } from "../constants/urls";
import { useWindowWidth } from "../hooks";
var Hardcover = lazy(function () {
    return import(/* webpackChunkName: "hardcover" */ "./Hardcover");
});
var Paperback = lazy(function () {
    return import(/* webpackChunkName: "paperback" */ "./Paperback");
});
var SwissBind = lazy(function () {
    return import(/* webpackChunkName: "swiss-bind" */ "./SwissBind");
});
var PdfViewer = lazy(function () {
    return import(/* webpackChunkName: "pdfviewer" */ "./PdfViewer");
});
var theme = createMuiTheme({
    palette: {
        primary: blueGrey,
        secondary: {
            main: "#f74f5a",
        },
    },
    typography: {
        fontFamily: ["Muli"].join(","),
    },
    overrides: {
        MuiAppBar: {
            colorDefault: { backgroundColor: "#fff" },
        },
    },
});
var useStyles = makeStyles({
    "@global": {
        "*": { margin: 0, padding: 0 },
    },
    appBar: {
        border: "1px solid #eee",
    },
    drawerHeader: {
        height: 64,
        display: "flex",
        alignItems: "center",
        margin: "0 16px",
    },
    logo: {
        width: 36,
        height: 36,
        marginRight: 8,
    },
    left: {
        overflowY: "auto",
        minWidth: 350,
        paddingRight: 16,
        flex: 2,
    },
    right: {
        overflowY: "auto",
        flex: 5,
    },
    content: {
        height: "calc(100% - 66px)",
        display: "flex",
        flexDirection: "row",
    },
    list: {
        width: 250,
        marginRight: 16,
    },
    listItem: {
        borderTopRightRadius: 24,
        borderBottomRightRadius: 24,
    },
    active: {
        backgroundColor: "#fce8e6",
        color: "#d93025",
        "&:hover": {
            backgroundColor: "#fce8e6",
        },
    },
    listItemText: {
        fontSize: 14,
    },
    title: {
        flexGrow: 1,
        marginLeft: 4,
    },
    root: {
        opacity: 0,
        bottom: 0,
        left: 0,
        position: "absolute",
        right: 0,
        top: 0,
        transition: "opacity .3s",
    },
    show: {
        opacity: 1,
    },
});
var MENU_ITEMS = [
    {
        label: HARDCOVER_TITLE,
        path: HARDCOVER_URL,
    }, {
        label: SWISS_BIND_TITLE,
        path: SWISS_BIND_URL,
    }, {
        label: PAPERBACK_TITLE,
        path: PAPERBACK_URL,
    },
];
var GOOGLE_FONTS = ["Muli:300,400,600"];
var Link = React.forwardRef(function (props, ref) { return (React.createElement(NavLink, __assign({ innerRef: ref }, props))); });
export default (function () {
    var _a = React.useState(false), open = _a[0], setOpen = _a[1];
    var _b = React.useState(false), fontActive = _b[0], setFontActive = _b[1];
    var title = useSelector(function (state) {
        return state.appbar.toJS().title;
    });
    var classes = useStyles({});
    var width = useWindowWidth();
    var isDesktop = width > 1200;
    React.useEffect(function () {
        WebFont.load({
            google: {
                families: GOOGLE_FONTS,
            },
            active: function () { return setFontActive(true); },
            inactive: function () { return setFontActive(false); },
        });
    });
    return (React.createElement(ThemeProvider, { theme: theme },
        !fontActive && React.createElement(Loading, null),
        React.createElement("div", { className: fontActive
                ? classes.root + " " + classes.show
                : classes.root },
            React.createElement(AppBar, { className: classes.appBar, position: "relative", color: "default", elevation: 0 },
                React.createElement(Toolbar, null,
                    React.createElement(IconButton, { onClick: function () { return setOpen(!open); }, color: "inherit", "aria-label": "Menu" },
                        React.createElement(MenuIcon, null)),
                    React.createElement(Typography, { className: classes.title }, title))),
            React.createElement(Drawer, { anchor: "left", open: open, onClose: function () { return setOpen(false); } },
                React.createElement(Toolbar, null,
                    React.createElement("img", { className: classes.logo, src: logo }),
                    React.createElement(Typography, { className: classes.title }, APP_TITLE)),
                React.createElement(Divider, null),
                React.createElement(List, { className: classes.list, dense: isDesktop }, MENU_ITEMS.map(function (item, index) { return (React.createElement(ListItem, { activeClassName: classes.active, button: true, className: classes.listItem, component: Link, key: index, to: item.path, onClick: function () { return setOpen(false); } },
                    React.createElement(ListItemText, { primary: item.label, className: classes.listItemText }))); }))),
            React.createElement("div", { className: classes.content },
                React.createElement("div", { className: classes.left },
                    React.createElement(Suspense, { fallback: React.createElement(Loading, null) },
                        React.createElement(Switch, null,
                            React.createElement(Route, { path: HARDCOVER_URL, component: Hardcover }),
                            React.createElement(Route, { path: SWISS_BIND_URL, component: SwissBind }),
                            React.createElement(Route, { path: PAPERBACK_URL, component: Paperback }),
                            React.createElement(Redirect, { from: "/", to: HARDCOVER_URL })))),
                isDesktop && (React.createElement("div", { className: classes.right },
                    React.createElement(Suspense, { fallback: React.createElement(Loading, null) },
                        React.createElement(PdfViewer, null))))),
            React.createElement(DownloadButton, null))));
});
