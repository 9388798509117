import { CONFIGURE_APP_BAR } from "../constants/action-types";
import { createTypedMap } from "../types/reducers";
var INITIAL_STATE = createTypedMap({
    title: "",
});
var reducer = function (state, action) {
    if (state === void 0) { state = INITIAL_STATE; }
    switch (action.type) {
        case CONFIGURE_APP_BAR:
            return state.merge(action.payload);
    }
    return state;
};
export default reducer;
