import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import appbar from "./appbar";
import output from "./output";
import pdf from "./pdf";
export var HISTORY = createBrowserHistory();
var rootReducer = combineReducers({
    appbar: appbar,
    pdf: pdf,
    output: output,
    router: connectRouter(HISTORY),
});
export default rootReducer;
