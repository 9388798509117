import React from "react";
import { useDispatch } from "react-redux";
import { APP_TITLE } from "../constants";
import { configureAppBar } from "../actions/appbar";
export var useWindowWidth = function () {
    var _a = React.useState(window.innerWidth), width = _a[0], setWidth = _a[1];
    React.useEffect(function () {
        var handleResize = function () { return setWidth(window.innerWidth); };
        window.addEventListener("resize", handleResize);
        return function () {
            window.removeEventListener("resize", handleResize);
        };
    });
    return width;
};
export var useConfigureApp = function (title) {
    var dispatch = useDispatch();
    React.useEffect(function () {
        dispatch(configureAppBar({ title: title }));
    }, []);
    React.useEffect(function () {
        document.title = title + " | " + APP_TITLE;
    }, []);
};
