import donwload from "downloadjs";
import React from "react";
import { connect } from "react-redux";
import Fab from "@material-ui/core/Fab";
import GetAppIcon from "@material-ui/icons/GetApp";
import { makeStyles } from "@material-ui/styles";
var useStyles = makeStyles({
    fab: {
        position: "fixed",
        bottom: 48,
        right: 48,
    },
});
export default connect(function (state) { return state.pdf.toJS(); })(function (_a) {
    var uri = _a.uri, name = _a.name;
    var classes = useStyles({});
    return (React.createElement(Fab, { color: "secondary", disabled: !uri, className: classes.fab, onClick: function () { return donwload(uri, name); } },
        React.createElement(GetAppIcon, null)));
});
