var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/styles";
var useStyles = makeStyles({
    progress: function (props) { return (__assign({ justifyContent: "center", display: "flex", alignItems: "center", transition: "visibility 0s, opacity 0.5s linear", height: "100%", visibility: "visible", opacity: 1 }, props)); },
});
export default (function (_a) {
    var _b = _a.loaded, loaded = _b === void 0 ? false : _b;
    var classes = useStyles(loaded ? { visibility: "hidden", opacity: 0, height: 0 } : {});
    return (React.createElement("div", { className: classes.progress },
        React.createElement(CircularProgress, null)));
});
