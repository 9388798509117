export { APP_TITLE } from "./globals";
export var DEFAULT_COVER_PAPER = {
    label: "Arctic Matt 250 g",
    thickness: 255,
    weight: 250,
};
export var DEFAULT_BOOK_BODY_PAPER = {
    label: "Arctic Volume ivory 150 g",
    thickness: 165,
    weight: 150,
};
export var COLOR_WEIGHT = 100;
export var QS_CONFIG = {
    allowDots: true,
    format: "RFC1738",
    ignoreQueryPrefix: true,
    skipNulls: true,
};
export var FIELD_SPACING = 2;
export var FIELD_VARIANT = "outlined";
export var FIELD_SIZE = "small";
