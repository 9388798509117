import React from "react";
import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import { Provider } from "react-redux";
import createRootReducer from "./reducers";
import { HISTORY } from "./reducers";
import App from "./containers/App";
var store = createStore(createRootReducer, composeWithDevTools(applyMiddleware(routerMiddleware(HISTORY), thunk)));
ReactDOM.render(React.createElement(Provider, { store: store },
    React.createElement(ConnectedRouter, { history: HISTORY },
        React.createElement(App, null))), document.getElementById("root"));
